import styled from 'styled-components';

export const CookieDeclarationStyles = styled.div`
  .CookieDeclarationType {
    border: none;
    color: #fff;
    padding: 2em 0;
  }

  .CookieDeclarationType:first-child {
    margin-top: 2em;
  }

  .CookieDeclarationTypeHeader {
    font-family: DomaineDispCondRegular, sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
  }

  .CookieDeclarationTableHeader {
    border-bottom: solid 1px #fff;
  }
  .CookieDeclarationTypeDescription {
    margin-bottom: 2em;
  }
  .CookieDeclarationTableCell {
    padding: 1em 4px 1em 4px;
  }
`;
